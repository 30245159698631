import axios, {AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance, Canceler} from 'axios'
import {useNavigate} from 'react-router-dom'
import {getAuthToken} from '../shared/utils/utilities'
import {SAVEUP_TOKEN_KEY} from '../shared/utils/constants'
import msalInstance, {tokenRequest} from '../components/auth/msalConfig'
import {IAuth} from '../redux/login/loginInterfaces'
const $baseURL: string = "https://api.saveup.se/api" // `${process.env.REACT_APP_PROXY_HOST}${process.env.REACT_APP_BASE_URL}` //  "http://localhost:65067/api"  process.env.REACT_APP_BASE_URL || '/api' //

const httpHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

const controller = new AbortController()

const config: AxiosRequestConfig = {
  baseURL: $baseURL,
  headers: httpHeaders,
  timeout: 10000,
  responseType: 'json',
  maxContentLength: 50000,
  maxBodyLength: 50000,
  maxRedirects: 5,
  signal: controller.signal,
}

export const handleResponse = (response: AxiosResponse) => response?.data

export const handleError = (error: AxiosError) => {
  if (error.response) {
    return error.response.status
  } else if (error.request) {
    return 'No response received'
  } else {
    return error.message
  }
}

const saveupClient: AxiosInstance = axios.create(config)

saveupClient.interceptors.request.use(
  async (config) => {
    let token: string | null = null

    const auth: IAuth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') as string) : null

    if (auth?.isAAD) {
      // Ensure we've got an active account set.
      let account = msalInstance.getActiveAccount()
      if (!account) {
        const accounts = msalInstance.getAllAccounts()
        if (accounts?.length > 0) {
          account = accounts[0]
        }
      }

      // Aquire the token silently if we have an account.
      if (account) {
        try {
          const tokenResponse = await msalInstance.acquireTokenSilent({...tokenRequest, account: account ? account : undefined})
          if (tokenResponse) {
            token = tokenResponse.accessToken
          }
        } catch (error) {
          console.error('Silent token acquisit ion failed: ', error)
        }
      }
    }

    // If no token was aquired from MSAL, lets try fetching a locally issued token.
    if (!token) {
      try {
        token = getAuthToken()
      } catch (e) {
        console.error('Error parsing auth data from localStorage: ', e)
      }
    }

    // If the config headers exist, append the token if available
    if (config.headers) {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      } else {
        // No token? Remove the Authorization header just in case
        delete config.headers.Authorization
      }
    }

    return config
  },
  async (error) => {
    console.error('Error in request interceptor:', error)
    return Promise.reject(error)
  }
)
saveupClient.interceptors.response.use(
  async (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      sessionStorage.removeItem(SAVEUP_TOKEN_KEY)
      localStorage.removeItem('auth')
      await msalInstance.logoutRedirect({postLogoutRedirectUri: '/'})
      window.location.href = '/login' // Redirect to login page
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)
export default saveupClient
